@import url('https://fonts.googleapis.com/css2?family=Krona+One&family=Gowun+Batang&display=swap');

:root {
  --primary-pink: #EC98B4;
  
  --primary-blue: #1c65aa;
  --primary-blue-transparent: #1c65aaef;

  --primary-cream: #edd3c8;
  --primary-green: #559e63;
  --secondary-pink: #e7bec4;
  --secondary-blue: #dee9f0;

  --secondary-blue-transparent: #dee9f065;



  --tertiary-blue: rgb(175, 198, 228);

  --primary-red: #915b5b;


}

html {
  scroll-behavior: smooth;
}

/* @media only screen and (max-width: 400px) 
{
  html {
    transform: scale(0.7);
    transform-origin: 0 0;
  }
} */

body {

  min-width: 360px;
  margin: 0;

  font-family: 'Gowun Batang', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
