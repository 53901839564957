#footer-tile
{
    text-align: center;

    max-width: 800px;

    border-radius: 100px;

    border: 3px dotted var(--secondary-blue);

    margin: 20px auto;

    padding: 10px;

}

.footer-info
{
    color: var(--secondary-blue);
    padding: 0 10px;
    white-space: pre-line;
}

.footer-children
{
    display: flex;
    flex-wrap: wrap
}