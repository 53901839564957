#nav-wrapper
{
    position: sticky;

    top: 0;

    width: 100%;
    /* height: 60px; */

    display: inline-block;

    background-color: var(--primary-blue-transparent);

    backdrop-filter: blur(20px);

    margin: 0;

    border-bottom: 0.5px solid var(--primary-pink);

    z-index: 100;
}

#nav-width
{
    padding: 0 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

#logo-icon
{
    width: 40px;

    margin: 15px 0;

    float: left;
}

#logo-text
{
    font-family: 'Krona One', sans-serif;
    /* font-family: 'Georama', sans-serif; */

    color: var(--primary-pink);
    
    margin: 22px 0 0 20px;

    font-weight: 700;

    display: inline-block;

    font-size: 130%;
}

@media only screen and (max-width: 400px) 
{
    #logo-text
    {
       font-size: 100%;
       margin: 26px 0 0 20px;
    }
    

}

.nav-link
{
    padding: 22px 0 0 30px;
    color: var(--primary-pink);
    text-decoration: none;
    text-decoration-line: underline;
    text-decoration-color: var(--primary-pink);
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;

    font-weight: 800;

    float: right;
}