.category-tile
{
    max-width: 800px;

    border-radius: 5px;

    background-color: var(--secondary-blue);

    margin: 30px auto;

    padding: 30px;

}

.anchor
{
    top: -150px;
    position: relative;
}

.category-title-aligner
{
    vertical-align: bottom;

}

.category-title
{
    color: var(--primary-blue);

    line-height: 3;

    font-family: 'Georama', sans-serif;
    font-weight: 900;
    font-size: 70%;
    letter-spacing: 7px;
    text-transform: uppercase;

    margin: 0;

    padding-bottom: 30px;

    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: var(--primary-blue);
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;

}

.category-description
{
    color: var(--primary-blue);

    margin: 0;

    padding-bottom: 30px;

    max-width: 400px;

    white-space: pre-line;
}

.category-children
{
    display: flex;
    flex-wrap: wrap;
    /* align-items: stretch; */
    /* justify-content: space-between; */
    gap: 30px;
}