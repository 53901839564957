#thing
{
  padding-top: 20px;
  padding-bottom: 40px;
  width: 200px;

  margin-left: auto;
  margin-right: auto;
}


#shape-1
{
  d: path("M242.9 99c-2.4-12.9-7.8-29.4-16.9-45.6c-6.6-11.7-15.1-23.2-25.8-33");
  transition: 1s;
}

#thing:hover #shape-1
{
  d: path("M244.5,75.5C238,59.7,241,65.1,229.5,50.5C215,32,210.9,30.2,200.2,20.4");

}

#shape-2 {
  d: path("M136.2,240.9C183.6,243.5,219.7,216.1,236.4,192.4");
  transition: 1s;
}
#thing:hover #shape-2 {
  d: path("M148.5,242.5C195.9,245.2,199,238.5,222.5,220.7");
}


#shape-3 {
  d: path("M189.1,265.1C192.4,256.8,194.2,245.7,194.5,231.7");
  transition: 1s;
}
#thing:hover #shape-3 {
  d: path("M201.5,266.5C205.5,250.4,203.2,251.5,203.5,237.5");
}


#shape-4 {
  d: path("M124.3,219C132.2,232.2,133.8,246.7,128.9,262.5");
  transition: 1s;
}
#thing:hover #shape-4 {
  d: path("M138.5,219.5C146.4,232.7,148.4,248.7,143.5,264.5");
}


#shape-5 {
  d: path("M209.7,151.6C207.8,154,207.9,162.2,209,164.4");
  transition: 1s;
}
#thing:hover #shape-5 {
  d: path("M213.7,161.6C211.8,164,211.9,172.2,213,174.4");
}


#shape-7 {
  d: path(
    "M364.9,240.7C322.1,227.7,291.6,249.7,268.4,237.2C242.9,223.3,231.3,188.5,234.1,163.4C237.8,131.4,249.1,38.7,307.2,10.3"
  );
  transition: 1s;
}
#thing:hover #shape-7 {
  d: path(
    "M352.5,245.5C309.7,232.4,300.4,249.1,269.5,245.5C228.6,238.9,209.5,208,215.5,163.5C219.2,131.5,249.1,38.7,307.2,10.3"
  );
}


#shape-8 {
  d: path("M295.8,277.9C292.4,268.8,290.6,259.5,290.2,244.2");
  transition: 1s;
}
#thing:hover #shape-8 {
  d: path("M278.5,275.5C275.2,266.4,274.8,261.9,274.5,246.5");
}


#shape-9 {
  d: path("M375.7,227.3C367.6,241.8,366,257.7,371,275.1");
  transition: 1s;
}
#thing:hover #shape-9 {
  d: path("M357.5,226.5C349.4,241,352.5,257.1,357.5,274.5");
}


#shape-10 {
  d: path("M291.6,158.4C293.5,160,293.3,165.8,292.1,167.4");
  transition: 1s;
}
#thing:hover #shape-10 {
  d: path("M258.6,164.4C260.5,166,260.3,171.8,259.1,173.4");
}


#shape-11 {
  d: path(
    "M477.6,186.2C495.6,145,492.3,120.6,486.3,91C480.2,61.4,461.2,30.2,461.9,7.5"
  );
  transition: 1s;
}
#thing:hover #shape-11 {
  d: path(
    "M467.5,205.5C498.5,165,492.3,120.6,486.3,91C480.2,61.4,461.2,30.2,461.9,7.5"
  );
}
