#category-chips
{
    max-width: 800px;

    border-radius: 5px;

    background-color: var(--secondary-blue);

    margin: 30px auto;

    padding: 30px;

    overflow: scroll;

    white-space: nowrap;

    overscroll-behavior: contain;

}

.category-chip
{
    display: inline-block;

    padding: 10px 15px;

    margin-right: 15px;

    border-radius: 30px;

    background-color: var(--secondary-pink);

    color: var(--primary-blue);

    font-weight: bold;

    border: 2px solid transparent;

    text-decoration: none;


    transition: all .2s ease-in-out;
}

.category-chip:hover
{
    border: 2px solid var(--primary-blue);

    transform: scale(1.05);
    
}