#item-tile
{
    width: 300px;
    border-radius: 5px;
    /* background-color: var(--primary-blue); */
    border: 0.5px solid var(--primary-blue);

    position: relative;

    overflow: hidden;

    margin-bottom: 30px;

    /* margin-right: 30px; */

    flex-grow: 1;



}

/* #item-title::before {
    position: absolute;
    top: -15px;
    width: 100%;
    height: 15px;
    background-image: linear-gradient(45deg, #1de0ef 50%, transparent 50%),
    linear-gradient(-45deg, #1de0ef 50%, transparent 50%);
    background-size: 30px 30px;
    content: '';
} */

.item-padding
{
    padding: 30px;
}

.item-image-container
{
    width: 100%;
    height: 150px;
    background-color: var(--secondary-pink);
}

.item-image{
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: top;
    opacity: 0.17;

    filter: saturate(0);
}

.item-image-container:after{
    content:'';
    position:absolute;
    left:0; top:0;
    width:inherit; height:inherit;
    display:inline-block;
    
    background: linear-gradient(to bottom, transparent 20%,var(--secondary-pink) 100%);
}

.item-top-aligner
{
    top: 0;
    height: 150px;
    width: 100%;
    /* vertical-align: text-bottom; */
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute;
}

.item-title
{

    color: var(--primary-blue);
    padding-left: 30px;

    margin-bottom: 15px;

    text-shadow: 0px 0px 50px var(--primary-pink), 0px 0px 50px var(--primary-pink);

}

.item-context
{
    margin-right: 15px;
    margin-left: 10px;

    min-width: max-content;

    float: right;

    padding: 5px 10px;

    background-color: var(--secondary-blue);

    border-radius: 5px;

    color: var(--primary-blue);

}

.item-description
{
    margin: 0;

    white-space: pre-line;

}

/* Pros */

.item-pros
{
    list-style: none;
    padding-left: 0;
}

.item-pros li{
    margin-bottom: 8px;
    margin-left: 15px;
}



.item-pros li:before {
    content: ' ✓ ';
    color: var(--primary-blue);
    margin-left: -15px;
}



/* Cons */

.item-cons
{
    list-style: none;
    padding-left: 0;
}

.item-cons li
{
    margin-bottom: 8px;
    margin-left: 15px;
}

.item-cons li:before {
    content: "X ";
    color: var(--primary-pink);
    margin-left: -15px;
}


.item-link
{

    color: var(--primary-blue);

    font-weight: bold;
    
    text-decoration: none;
    text-decoration-line: underline;
    text-decoration-color: var(--primary-blue);
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;

    display: block;

    margin-bottom: 10px;

}

.item-link:last-child
{
    margin-bottom: none;

}