#introduction-tile
{
    position: relative;
    text-align: center;

    max-width: 800px;

    border-radius: 400px;

    background-color: var(--secondary-pink);

    margin: 30px auto;

    padding: 30px;

}

#introduction-tile:after {
    content: " ";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 3px dashed var(--primary-blue);

    border-radius: 400px;

    pointer-events: none;
  }

.introduction-image
{
    padding-top: 20px;
    padding-bottom: 40px;
    width: 200px;
}

.introduction-title
{
    color: var(--primary-blue);

    margin: 0;

    padding-bottom: 30px;
}

.introduction-description
{
    color: var(--primary-blue);

    margin: 0;

    padding: 0 40px 30px 40px;

    max-width: 400px;

    margin: auto;

    white-space: pre-line;
}

.introduction-children
{
    display: flex;
    flex-wrap: wrap
}